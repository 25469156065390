import { detectCloseElement } from "./helper";

export const formInit = {
	slider: $("#slider-acreage")[0],
	closeFilter: function () {
		$(".form-filter").removeClass("isOpen");
		$("body").removeClass("disable-scroll");
	},
	clickCloseFilter: function () {
		$("body").on("click", ".btn-close", function () {
			formInit.closeFilter();
		});
	},
	formSelectOpen: function () {
		$("body").on("click", ".form-group-select .select-button", function () {
			if (window.matchMedia("(max-width: 1024px)").matches) {
				if ($(".select-options").is(":visible")) {
					$(this).closest(".form-group-select").find(".select-options").slideToggle();
					$(this).closest(".form-group-select").toggleClass("isOpen");
				} else {
					$(this).closest(".form-group-select").find(".select-options").slideToggle();
					$(this).closest(".form-group-select").toggleClass("isOpen");
				}
			} else {
				$(".form-group-select").not($(this).closest(".form-group-select")).removeClass("isOpen");
				$(this).closest(".form-group-select").toggleClass("isOpen");
			}
		});
		detectCloseElement(".form-group-select", ".select-button", function () {
			$(".form-group-select").removeClass("isOpen");
		});
	},
	formProcessAjax: function (queryString, endpoint) {
		$.ajax({
			url: endpoint,
			method: "GET",
			data: queryString,
			beforeSend: function () {
				$("body").addClass("isLoadingFilter");
				$("body").append('<div class="loading-filter"><div class="loading-filter-inner"></div></div>');
			},
			success: function (response) {
				const responseHtml = response.html;
				const isHasResponse = $(responseHtml).hasClass("ajaxResponse");
				if (isHasResponse) {
					$(".ajaxResponse").html($(responseHtml).html());
				} else {
					$(".ajaxResponse").html($(responseHtml).find(".ajaxResponse").html());
				}
			},
			error: function (xhr, status, error) {
				console.error("Error fetching projects:", error);
			},
			complete: function () {
				console.log("queryString", queryString);
				$("body").removeClass("isLoadingFilter");
				$(".loading-filter").remove();

				window.observer.observe();
				// Push query string to URL
				const newUrl = window.location.pathname + "?" + queryString;
				history.pushState({ path: newUrl }, "", newUrl);
			},
		});
	},
	formSubmitFilter: function () {
		$("form.form-filter").on("submit", function (e) {
			e.preventDefault();
			let formData = $(this).serializeArray();
			let mergedData = {};
			formData.forEach(({ name, value }) => {
				if (value) {
					if (name === "price_range") {
						const [min, max] = value.split(",");
						mergedData["price_min"] = min;
						mergedData["price_max"] = max;
					} else {
						mergedData[name] = mergedData[name] ? `${mergedData[name]},${value}` : value;
					}
				}
			});
			let queryString = new URLSearchParams(mergedData).toString().replace(/%2C/g, ",");
			let endpoint = $(this).attr("action");
			formInit.formProcessAjax(queryString, endpoint);
			formInit.closeFilter();
		});
	},
	formClearFilter: function () {
		$("form.form-filter").on("click", ".clear-filter", function (e) {
			e.preventDefault();
			const $form = $(this).closest("form.form-filter");

			// Uncheck all checkboxes
			$form.find('input[type="checkbox"], input[type="radio"]').prop("checked", false);

			// Reset all select button texts
			$form.find(".form-group-select .select-button-text").each(function () {
				const $this = $(this);
				$this.text($this.data("default-text") || $this.text());
			});

			// Reset range slider if it exists
			if (formInit.slider) {
				formInit.slider.noUiSlider.reset();
			}

			$form.find("input").trigger("change");
			formInit.updateSelectButtonText();
			formInit.closeFilter();
			$("form.form-filter").trigger("submit");
		});
	},
	formSliderRange: function () {
		console.log("🟢 noUiSliderInit");
		if (formInit.slider) {
			noUiSlider.create(formInit.slider, {
				start: 500,
				connect: [true, false],
				range: {
					min: 0,
					max: 1000,
				},
			});
			const $acreageMax = $("#acreage-max");

			formInit.slider.noUiSlider.on("update", function (values, handle) {
				const value = parseFloat(values[handle]).toFixed(0);
				if (handle === 0) {
					$acreageMax.val(value);
					$acreageMax.next(".value").text(value);
				}
			});
		}
	},
	updateSelectButtonText: function () {
		const updateText = function ($formGroupElement) {
			const $selectButton = $formGroupElement.find(".select-button-text");
			const $checkedBoxes = $formGroupElement.find('input[type="checkbox"]:checked, input[type="radio"]:checked');

			if ($checkedBoxes.length === 0) {
				$selectButton.text($selectButton.data("default-text") || $selectButton.text());
			} else if ($checkedBoxes.length === 1) {
				$selectButton.text($checkedBoxes.next("label").text());
			} else {
				$selectButton.text(`${$checkedBoxes.length} đã chọn`);
			}
		};
		// Handle checkbox changes
		$("body").on("change", '.form-group-select input[type="checkbox"], .form-group-select input[type="radio"]', function () {
			updateText($(this).closest(".form-group-select"));
		});

		// Allow manual updates for all form groups
		$(".form-group-select").each(function () {
			console.log("🟢 updateText", $(this));
			updateText($(this));
		});
	},
	btnFilterMobile: function () {
		$("body").on("click", ".btn-filter-mobile", function () {
			$(".form-filter").toggleClass("isOpen");
			$("body").toggleClass("disable-scroll");
		});
	},
	productDetail: function () {
		const $container = $(".section-products");
		const projectId = $container.data("project-id");
		const pageSize = 8;
		let currentPage = 1;

		function updateActiveState($element) {
			$element.addClass("active").siblings().removeClass("active");
		}

		function fetchProducts(queryParams) {
			const endpoint = "/ajax/detail-properties";
			const queryString = new URLSearchParams(queryParams).toString();

			$.ajax({
				url: `${endpoint}?${queryString}`,
				method: "GET",
				beforeSend: function () {
					$("body").addClass("isLoadingFilter");
					$("body").append('<div class="loading-filter"><div class="loading-filter-inner"></div></div>');
				},
				success: function (response) {
					$(".ajaxResponse").html($(response).html());
					updateProductVisibility();
				},
				error: function (xhr, status, error) {
					console.error("Error fetching products:", error);
				},
				complete: function () {
					window.observer.observe();
					$("body").removeClass("isLoadingFilter");
					$(".loading-filter").remove();
				},
			});
		}
		function updateProductVisibility() {
			const $products = $(".ajaxResponse .item-product");
			const totalProducts = $products.length;

			$products.hide();
			$products.slice(0, currentPage * pageSize).show();

			if (totalProducts > currentPage * pageSize) {
				if (!$(".btn-expand-more").length) {
					$(".ajaxResponse").append('<button class="btn-expand-more">View More</button>');
				}
			} else {
				$(".btn-expand-more").remove();
			}
		}

		// Handle btn-type clicks
		$container.on("click", ".btn-type", function (e) {
			e.preventDefault();
			const $this = $(this);
			updateActiveState($this);

			const type = $this.data("id");
			const room = $(".tabslet-tab-primary .active a").data("room") || "all";

			currentPage = 1;
			fetchProducts({ project_id: projectId, type, bedrooms: room });
		});

		// Handle data-room clicks
		$container.on("click", ".tabslet-tab-primary a", function (e) {
			e.preventDefault();
			const $this = $(this);
			updateActiveState($this.parent());

			const room = $this.data("room");
			const type = $(".btn-type.active").data("id");

			currentPage = 1;
			fetchProducts({ project_id: projectId, type, bedrooms: room });
		});
		$container.on("click", ".btn-expand-more", function (e) {
			e.preventDefault();
			currentPage++;
			updateProductVisibility();
		});
	},
	checkInputsFromUrl: function () {
		const urlParams = new URLSearchParams(window.location.search);
		let mergedParams = {};

		// Pre-process URL parameters
		urlParams.forEach((value, key) => {
			if (key === "price_min" || key === "price_max") {
				mergedParams["price_range"] = mergedParams["price_range"] || [];
				mergedParams["price_range"][key === "price_min" ? 0 : 1] = value;
			} else {
				mergedParams[key] = value;
			}
		});

		// Handle merged parameters
		Object.entries(mergedParams).forEach(([key, value]) => {
			if (key === "price_range") {
				const priceRange = Array.isArray(value) ? value.join(",") : value;
				$(`input[name="price_range"][value="${priceRange}"]`).prop("checked", true).trigger("change");
			} else if (key.includes("acreage")) {
				$(`#${key}`).val(value).trigger("change");
				if (formInit.slider && key === "acreage-max") {
					formInit.slider.noUiSlider.set(value);
				}
			} else {
				const values = value.split(",");
				values.forEach((val) => {
					$(`input[name="${key}"][value="${val}"]`).prop("checked", true).trigger("change");
				});
			}
		});
		formInit.updateSelectButtonText();
	},
	init: function () {
		formInit.formSliderRange();
		formInit.formSelectOpen();
		formInit.formSubmitFilter();
		formInit.updateSelectButtonText();
		formInit.formClearFilter();
		formInit.btnFilterMobile();
		formInit.clickCloseFilter();
		formInit.productDetail();
		formInit.checkInputsFromUrl();
	},
};
window.formInit = formInit;
