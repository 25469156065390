import Swiper from "swiper";
import { Autoplay, Controller, EffectFade, FreeMode, Grid, Navigation, Pagination, Thumbs } from "swiper/modules";
/**
 * @param swiperInit
 */
export function swiperInit() {
	new Swiper(".section-home-banner .swiper", {
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 1000,
		loop: true,
		effect: "fade",
		autoplay: {
			delay: 3500,
		},
		modules: [Pagination, Navigation, Autoplay, EffectFade],
		navigation: {
			nextEl: ".section-home-banner .btn-next",
			prevEl: ".section-home-banner .btn-prev",
		},
		pagination: {
			el: ".section-home-banner .swiper-pagination",
			clickable: true,
		},
	});
	const thumbsSwiper = new Swiper(".section-buy-detail.section-banner .wrap-thumb-banner .swiper", {
		slidesPerView: 3,
		spaceBetween: 10,
		watchSlidesProgress: true,
		freeMode: true,
		modules: [Autoplay, FreeMode],
		breakpoints: {
			768: {
				slidesPerView: 3,
				spaceBetween: 12,
			},
			1024: {
				slidesPerView: 6,
				spaceBetween: 18,
			},
		},
	});
	new Swiper(".section-buy-detail.section-banner .wrap-banner-main .swiper", {
		slidesPerView: 1.3,
		spaceBetween: 12,
		centeredSlides: true,
		loop: true,
		modules: [Navigation, Autoplay, Thumbs],
		thumbs: {
			swiper: thumbsSwiper,
		},
		navigation: {
			nextEl: ".section-buy-detail.section-banner .btn-next",
			prevEl: ".section-buy-detail.section-banner .btn-prev",
		},
		breakpoints: {
			768: {
				slidesPerView: 2.5,
				spaceBetween: 12,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 12,
			},
		},
	});
	$(".swiper-cols-3 .swiper").each(function () {
		new Swiper(this, {
			slidesPerView: 1,
			spaceBetween: 20,
			modules: [Navigation],
			navigation: {
				nextEl: $(this).closest(".swiper-cols-3").find(".btn-next")[0],
				prevEl: $(this).closest(".swiper-cols-3").find(".btn-prev")[0],
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1200: {
					slidesPerView: 3,
				},
			},
		});
	});
	new Swiper(".section-buy-detail .wrap-other-product .swiper", {
		slidesPerView: 1,
		spaceBetween: 20,
		modules: [Navigation],
		navigation: {
			nextEl: ".section-buy-detail .tab-other-product .btn-next",
			prevEl: ".section-buy-detail .tab-other-product .btn-prev",
		},
	});
	new Swiper(".section-buy-detail .product-image .swiper", {
		slidesPerView: 1,
		spaceBetween: 20,
		modules: [Navigation],
		navigation: {
			nextEl: ".section-buy-detail .product-image .btn-next",
			prevEl: ".section-buy-detail .product-image .btn-prev",
		},
	});
	$(".swiper-cols-1 .swiper").each(function () {
		new Swiper(this, {
			slidesPerView: 1,
			spaceBetween: 20,
			modules: [Navigation],
			navigation: {
				nextEl: $(this).closest(".swiper-cols-1").find(".btn-next")[0],
				prevEl: $(this).closest(".swiper-cols-1").find(".btn-prev")[0],
			},
		});
	});
	new Swiper(".section-buy-detail .wrap-slide-utility .swiper", {
		slidesPerView: 1,
		spaceBetween: 0,
		modules: [Grid, Pagination],
		pagination: {
			el: ".section-buy-detail .wrap-slide-utility .swiper-pagination",
			clickable: true,
		},
		grid: {
			rows: 1,
			fill: "row",
		},
		breakpoints: {
			768: {
				slidesPerView: 3,
				grid: {
					rows: 2,
				},
			},
			1024: {
				slidesPerView: 4,
				grid: {
					rows: 2,
				},
			},
		},
	});
	new Swiper(".section-buy-detail .tab-news-related .swiper", {
		slidesPerView: 1,
		spaceBetween: 20,
		modules: [Grid, Navigation],
		navigation: {
			nextEl: ".section-buy-detail .tab-news-related .btn-next",
			prevEl: ".section-buy-detail .tab-news-related .btn-prev",
		},
		grid: {
			rows: 2,
			fill: "row",
		},
		breakpoints: {
			1024: {
				slidesPerView: 2,
				spaceBetween: 20,
				grid: {
					rows: 2,
				},
			},
		},
	});
	$(".swiper-cols-4 .swiper").each(function () {
		new Swiper(this, {
			slidesPerView: 1,
			spaceBetween: 20,
			modules: [Navigation],
			navigation: {
				nextEl: $(this).closest(".swiper-cols-4").find(".btn-next")[0],
				prevEl: $(this).closest(".swiper-cols-4").find(".btn-prev")[0],
			},
			breakpoints: {
				768: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
				},
			},
		});
	});
	// about 3
	const swiperAbout3_image = new Swiper(".section-about-3 .col-slider-img .swiper", {
		slidesPerView: 1,
		spaceBetween: 20,
		modules: [Navigation],
		navigation: {
			nextEl: ".section-about-3 .col-slider-img .btn-next",
			prevEl: ".section-about-3 .col-slider-img .btn-prev",
		},
	});
	const swiperAbout3_year = new Swiper(".section-about-3 .col-slider-year .wrapper-slider-year .swiper", {
		slidesPerView: 3,
		spaceBetween: 15,
		modules: [Navigation, FreeMode],
		watchSlidesProgress: true,
		freeMode: true,
		breakpoints: {
			768: {
				slidesPerView: 5,
				spaceBetween: 50,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 0,
			},
		},
	});
	const swiperAbout3_content = new Swiper(".section-about-3 .col-slider-year .wrapper-slider-year-content .swiper", {
		slidesPerView: 1,
		spaceBetween: 20,
		modules: [Navigation, Thumbs, Controller, FreeMode],
		controller: {
			control: swiperAbout3_image,
		},
		thumbs: {
			swiper: swiperAbout3_year,
		},
		navigation: {
			nextEl: ".section-about-3 .col-slider-year .btn-next",
			prevEl: ".section-about-3 .col-slider-year .btn-prev",
		},
		on: {
			init: function () {
				calculateProcessBar(this);
			},
			slideChange: function () {
				calculateProcessBar(this);
			},
		},
	});
}

function calculateProcessBar(swiper) {
	let activeThumbSlide = $(".wrapper-slider-year .swiper-wrapper .swiper-slide-thumb-active");
	let dot = activeThumbSlide.find(".circle-year");
	let isLastDot = activeThumbSlide.is(":last-child");
	let relativeOffsetDot = isLastDot ? "10000px" : dot.offset().left - $(".wrapper-slider-year .swiper-wrapper").offset().left;
	let stepBarInnerWidth = isLastDot ? "10000px" : relativeOffsetDot + dot.width() / 2 + "px";
	$(".process-bar-year").attr("style", `--process: ${stepBarInnerWidth}`);
}
