import AOS from "aos";
import lozad from "lozad";
import { setBackgroundElement, buttonToTop, ToggleItem } from "./helper";
import { header } from "./header";
import { swiperInit } from "./swiper";
import { CountUp } from "countup.js";
import { formInit } from "./form";
$(document).ready(function () {
	buttonToTop();
	setBackgroundElement();
	header.init();
	swiperInit();
	countUpInit();
	homeHoverItemSelling();
	footerInit();
	PopupHomeInit();
	makeIds();
	generateToc();
	formInit.init();
	ToggleItem();
	menuSpyInit();
	expandContentInit();
	handleTabsletSub();
	handleClickMap();
	handleClickFocusForm();
	copyUrlInit();
	scrollToTabActive();
});
/*==================== Scroll to tab active ====================*/
function scrollToTabActive() {
	const $container = $(".tabslet-tab-secondary");
	const $activeTab = $container.find(".active");
	if ($activeTab.length) {
		const containerLeft = $container.offset().left;
		const activeTabLeft = $activeTab.offset().left;
		const scrollLeft = activeTabLeft - containerLeft - $container.width() / 2 + $activeTab.width() / 2;
		// const scrollLeft = activeTabLeft - containerLeft;
		$container.animate(
			{
				scrollLeft: scrollLeft,
			},
			300
		);
	}
}
/*==================== Copy Url ====================*/
function copyUrlInit() {
	$(".btn-copy-url").on("click", function (e) {
		e.preventDefault();
		navigator.clipboard.writeText(window.location.href);
		alert("Đã copy đường dẫn thành công");
	});
}
/*==================== Module - Page Project ====================*/
function handleClickFocusForm() {
	$(".btn-primary[href='#form-contact']").on("click", function (e) {
		e.preventDefault();
		$("body").addClass("focus-form");
		$("#form-contact").addClass("active");
		$("#full_name").focus();
		setTimeout(() => {
			$("#form-contact").removeClass("active");
			$("body").removeClass("focus-form");
		}, 2500);
	});
}
function handleClickMap() {
	$(".item-iframe").on("click", function () {
		const dataUrl = $(this).data("map-iframe");
		$(this).closest(".tabslet-content").find(".wrap-map-iframe iframe").attr("src", dataUrl);
	});
}
function handleTabsletSub() {
	$('[data-toggle-sub="tabslet"]').each(function () {
		const $tabsletContainer = $(this);
		const $tabs = $tabsletContainer.find(".tabslet-tab-sub li");
		const $contents = $tabsletContainer.find(".tabslet-content-sub");

		$tabs.on("click", function (e) {
			e.preventDefault();
			const $clickedTab = $(this);
			const targetId = $clickedTab.find("a").attr("href");

			// Remove active class from all tabs and contents within this tabslet
			$clickedTab.closest(".tabslet-tab-sub").find("li").removeClass("active");
			$contents.removeClass("active").addClass("hidden");

			// Add active class to clicked tab and corresponding content
			$clickedTab.addClass("active");
			$tabsletContainer.find(targetId).removeClass("hidden").addClass("active");
		});
	});
}
/*==================== Expand Content ====================*/
function expandContentInit() {
	$(".btn-expand").each(function () {
		const isText = $(this).prev().hasClass("expand-content-text");
		const isItem = $(this).prev().hasClass("expand-content-item");
		const isTable = $(this).prev().hasClass("expand-content-table");
		if (isText) {
			console.log($(this).prev().children().length);
			if ($(this).prev().children().length < 2) {
				$(this).hide();
			}
		}
		if (isItem) {
			if ($(this).prev().children().length <= 8) {
				$(this).hide();
			}
		}
		if (isTable) {
			if ($(this).prev().find("table:eq(0) tbody").children().length <= 8) {
				$(this).hide();
			}
		}
	});
	$(".btn-expand").on("click", function (e) {
		e.preventDefault();
		$(this).prev().toggleClass("show");
		$(this)
			.toggleClass("active")
			.text($(this).prev().hasClass("show") ? "Thu gọn" : "Xem thêm");
		return false;
	});
}
/*==================== MenuSpy ====================*/
function menuSpyInit() {
	var elm = document.querySelector("#menu-spy");
	if (!elm) return;
	var ms = new MenuSpy(elm, {
		activeClass: "active",
		threshold: 200,
		callback: scrollToTabActive,
	});
}
/*==================== Generate Toc ====================*/
function makeIds() {
	// eslint-disable-line
	if (!$("#main-content").length) return;
	var content = document.querySelector("#main-content");
	var headings = content.querySelectorAll("h1, h2, h3, h4, h5, h6, h7");
	var headingMap = {};

	if (!headings.length) {
		$(".wrap-toc").remove();
		return;
	}

	Array.prototype.forEach.call(headings, function (heading) {
		var id = heading.id
			? heading.id
			: heading.textContent
					.trim()
					.toLowerCase()
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.replace(/đ/g, "d")
					.replace(/\s+/g, "-")
					.replace(/[^a-z0-9-]/g, "");
		headingMap[id] = !isNaN(headingMap[id]) ? ++headingMap[id] : 0;
		if (headingMap[id]) {
			heading.id = id + "-" + headingMap[id];
		} else {
			heading.id = id;
		}
	});
	$("body").on("click", ".toc .title", function () {
		$(this).toggleClass("active");
		$(this).closest(".toc").find(".js-toc").slideToggle();
	});
}
function generateToc() {
	$(".title-toc").click(function () {
		$(this).closest(".wrap-toc").toggleClass("open").find(".toc").slideToggle();
	});
	tocbot.init({
		tocSelector: ".js-toc",
		contentSelector: ".js-toc-content",
		headingSelector: "h1, h2, h3, h4, h5",
		scrollSmooth: true,
		activeLinkClass: "is-active-link",
		scrollSmoothOffset: -90,
		scrollSmoothDuration: 420,
		headingsOffset: 90,
		orderedList: true,
	});
}
/*==================== Popup Form ====================*/
function PopupHomeInit() {
	if (!$("#popup-form-contact").length) return;
	setTimeout(() => {
		Fancybox.show([{ src: "#popup-form-contact", type: "inline" }]);
	}, 3500);
}
/*==================== Footer ====================*/
function footerInit() {
	if (window.matchMedia("(min-width: 1024px)").matches) return;
	$(".col-footer-links .footer-title").click(function () {
		$(this).closest(".col-footer-links").toggleClass("open").find(".footer-list-links").slideToggle();
	});
}
/*==================== Home Selling ====================*/
function homeHoverItemSelling() {
	if (!window.matchMedia("(min-width: 1024px)").matches) return;
	$(".item-product-secondary").hover(
		function () {
			$(this).closest(".wrap-item-product").addClass("hover");
			const imageUrl = $(this).data("image-url");
			$(".item-product-image-full").attr("src", imageUrl);
		},
		function () {
			$(this).closest(".wrap-item-product").removeClass("hover");
		}
	);
}
/*==================== Count Up ====================*/
function countUpInit() {
	const countUpElements = document.querySelectorAll(".count-up");
	countUpElements.forEach((element) => {
		const targetNumber = element.getAttribute("data-number");
		// Check if number is decimal values
		const is_decimal = targetNumber.includes(".");
		const countUp = new CountUp(element, targetNumber, {
			duration: 2.5,
			separator: ".",
			decimal: ",",
			enableScrollSpy: true,
			decimalPlaces: is_decimal ? 2 : 0,
		});
		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}
	});
}
/*==================== Aos Init ====================*/
AOS.init({
	offset: 100,
});
/*==================== Lazyload JS ====================*/
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
window.observer = observer;
observer.observe();
